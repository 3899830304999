<template>
    <div class="main-pdding">
        <div class="main-box">
            <div class="main-between">
                <div class="title">
                    <label>参数模版</label>
                </div>
                <div class="new-product">
                    <el-button type="primary" icon="el-icon-plus" @click="addproduct">添加模版</el-button>
                </div>
            </div>
           
            <div class="info-table search">
                <infoTable :selection="false" :tableLoading="loading" :tableHeadConfig="tableHead" :tableLoadData="productData">
                   <template v-slot:content="slotData">
                        <div class="param-content" v-for="(item,index) in slotData.data.row.content" :key="index">
                            <span>{{'参数名:'+item.key+','}}</span>
                            <span>{{'参数值:'+item.value+';  '}}</span>
                        </div>
                   </template>
                    <template v-slot:operation="slotData">
                        <div class="operation-sty">
                            <span @click="goEdit(slotData.data.row.id)">
                                编辑
                            </span>
                            <span @click="delInfo(slotData.data.row.id)">
                                删除
                            </span>
                        </div>
                    </template>
                </infoTable>
            </div>
        </div>
        <deldialog :appId="appId" :dialogVal="delDialog" title="您确定要删除该参数模版吗?" @dialogChange="delChange">
        </deldialog>
    </div>
</template>

<script>
    import { sessionGetKey } from "@/utils/sessionStorage.js";
    export default {
        data() {
            return {
                delDialog:false,
                appId:'',
                platform_id:'',
                pageData: {
                    currentpage: 1,
                    total: 300,
                    totalpage: 30
                },
                searchData: {
                    name: '',
                    classify: '',
                    addTime: ''
                },
                classifyData: [],
                loading:false,
                tableHead: [
                    {
                        label: "参数模版名称",
                        field: "name"
                    },
                    {
                        label: "参数内容",
                        columnType: "slot",
                        slotName: "content",
                        width:800
                    },
                    {
                        label: "操作",
                        columnType: "slot",
                        slotName: "operation"
                    }
                ],
                productData: []
            }
        },
        mounted(){
             this.platform_id = sessionGetKey("platformId")
             this.getList()
            //  JSON.parse
        },
        methods: {
            addproduct(){
                this.$router.push({
                    path:'/productMent/template/addtemplate',
                    query:{type:'1'}
                })
            },
            goEdit(id){
                this.$router.push({path:'/productMent/template/addtemplate',query:{id:id,type:'2'}})
            },
            // 获取参数模版
            getList(){
                const that = this
                that.loading = true
                that.$request({
                    url:that.$api.goodTemplate.getTemp,
                    method:'post',
                    params:{
                        platform_id:that.platform_id
                    }
                }).then(res=>{

            
                   
                    let arr=[]

               if(Array.isArray(res.data)){
                   arr = res.data;
                }else{
                    arr=Object.values(res.data)
                }
                 arr.forEach(item => {
                        item.content = JSON.parse(item.content)
                    });
 that.productData=arr
                    that.loading = false
                })
            },
            //删除
            delInfo(id){
                this.appId= id
                this.delDialog = true
            },
            delChange(type,id){
                this.delDialog = false
                if(type == 1){
                    this.$request({
                        url:this.$api.goodTemplate.delTemp,
                        method:'post',
                        data:{id},
                        params:{
                            platform_id:this.platform_id
                        }
                    }).then(res=>{
                        this.getList()
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .title {
        label {
            display: inline-block;
            height: 30px;
            color: #4458FE;
            font-size: 20px;
            font-weight: 600;
            border-bottom: 2px solid #4458FE;
        }
    }

    .search {
        margin-top: 15px;
    }

    .info-table {
        min-height: 400px;

        .sort-sty {
            span {
                font-size: 16px;
            }

            img {
                margin-left: 10px;
                width: 22px;
                height: 22px;
            }
        }

        .state-young {
            width: 80px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            color: #01D0B5;
            background-color: #E5FFFC;
            border: 1px solid #01D0B5;
            border-radius: 6px;
        }

        .state-red {
            width: 80px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            color: #FE725C;
            background-color: #FFE6E2;
            border: 1px solid #FE725C;
            border-radius: 6px;
        }

        .operation-sty {
            span {
                color: #4458FE;
            }

            span:before {
                content: "|";
                margin: 0 5px;
                color: #4458FE;
            }

            span:first-child:before {
                display: none;
            }
        }
    }

    .param-content{
        display: inline;
    }
</style>